import React, { Component } from 'react';
import './checkbox.scss';

export default class Contact extends Component {
    render() {
      return (
        <div className="checkbox">
          <input id={this.props.id} type="checkbox"/>
          <label htmlFor={this.props.id}>
            <svg>
              <polyline 
                points="8,17 16,27 32,9"
              />
            </svg>
          </label>            
          <p>{this.props.text && this.props.text}</p>
        </div>
      )        
    }
}