import React, { Component } from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import MobileDetect from 'mobile-detect';
import { Scrollbars } from 'react-custom-scrollbars';
import Contact from './pages/contact';
import MobileContact from './pages/contact/mobileContact';
import OurWork from './pages/ourWork';
export default class App extends Component {
  state = {
    lang: 'KR',
    winHeight: window.innerHeight,
    winWidth: window.innerWidth,
    scrollTop: 0,
    isTablet: null,
    isMobile: null,
  }

  updateDimensions = () => {
    this.setState({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    const md = new MobileDetect(window.navigator.userAgent);
    const isTablet = md.tablet();
    const isMobile = md.mobile();
    this.setState({
      isTablet,
      isMobile
    });
    document.body.style.height = isTablet || isMobile ? 'auto' : '100%';
    document.body.style.overflow = isTablet || isMobile ? 'auto' : 'hidden';
    document.getElementById('root').style.height = isTablet || isMobile ? 'auto' : '100%';
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  handleScroll = e => {
    const top = Math.round(e.target.scrollTop);
    this.setState({ scrollTop: top });
  }

  setlang = (lang) => this.setState({ lang });

  render() {
    const { isTablet, isMobile } = this.state;
    const contactComp = (!isTablet && !isMobile)
    ? <Scrollbars
        style={{ width: '100%', height: this.state.winHeight }}
        onScroll={this.handleScroll}
        ref="scrollbar"
      >        
        <Contact {...this.state} setlang={this.setlang} isTablet={isTablet}/>
      </Scrollbars>
    : isTablet 
      ? <Contact {...this.state} setlang={this.setlang} isTablet={isTablet} />
      : <MobileContact {...this.state} setlang={this.setlang}/>;

    const OurWorkComp = (!isTablet && !isMobile)
    ? <Scrollbars
        style={{ width: '100%', height: this.state.winHeight }}
        onScroll={this.handleScroll}
        ref="scrollbar"
      >
        <OurWork isTablet={isTablet} isMobile={isMobile}/>
      </Scrollbars>
    : <OurWork isTablet={isTablet} isMobile={isMobile} />
    return (      
      <Router>
        <div 
          className={this.state.lang}
          onWheel={this.onWheel}
          style={{ height: isTablet || isMobile ? 'auto' : '100%' }}
        >
          <Route
            exact
            path="/"
            render={() => OurWorkComp}
          />
          <Route
            path="/contact_us"
            render={() => contactComp}
          />
          <Route
            path="/our_work"
            render={() => OurWorkComp}
          />
        </div>  
      </Router>    
    );
  }
}
