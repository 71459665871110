import React, { Component } from 'react';

export default class ScrollBtn extends Component {
    render() {
      return (
        <div className={`scroll_btn ${!this.props.isFix ? 'fix' : ''}`} onClick={() => {
          if (!this.props.isFix) {
            document.getElementById('root').children[0].children[0].children[0].scrollTop = 2000;
          } else {
            document.getElementById('root').children[0].children[0].children[0].scrollTop = 0;
          }
        }}>
          <svg>
              <polygon points={`${!this.props.isFix ? '0,0 10,17 20,0' : '0,20 10,0 20,20'}`}/>
          </svg>
          <p>{this.props.isFix ? 'scroll up' : 'scroll down'}</p>              
        </div>
      )
    }
}
