import React, { Component } from 'react';

export default class Behance extends Component {
    render() {
      return (
        <svg version="1.1" id="레이어_1" xmlns="http://www.w3.org/2000/svg" x="0px"
          y="0px" viewBox="0 0 30 30" xmlSpace="preserve">
        <g transform="translate(0 -192.173)">
          <path d="M8.72,197.75c0.81-0.02,1.62,0.07,2.4,0.27c0.66,0.12,1.3,0.37,1.86,0.73c0.53,0.33,0.95,0.82,1.2,1.4
            c0.28,0.68,0.41,1.4,0.4,2.13c0.04,0.83-0.19,1.65-0.67,2.33c-0.48,0.66-1.12,1.19-1.86,1.53c1.02,0.26,1.92,0.87,2.53,1.73
            c0.57,0.87,0.87,1.89,0.87,2.93c0.01,0.83-0.17,1.65-0.53,2.4c-0.34,0.67-0.84,1.24-1.46,1.66c-0.65,0.43-1.37,0.75-2.13,0.93
            c-0.78,0.21-1.59,0.32-2.4,0.33H0v-18.39H8.72 M8.19,205.21c0.64,0.02,1.27-0.17,1.8-0.53c0.48-0.41,0.73-1.03,0.67-1.66
            c0.01-0.37-0.06-0.73-0.2-1.07c-0.14-0.27-0.35-0.5-0.6-0.67c-0.27-0.15-0.56-0.26-0.87-0.33c-0.35-0.06-0.71-0.08-1.07-0.07H4.06
            v4.33L8.19,205.21z M8.39,213.07c0.38-0.01,0.76-0.05,1.13-0.13c0.34-0.06,0.65-0.2,0.93-0.4c0.27-0.19,0.5-0.44,0.67-0.73
            c0.17-0.38,0.26-0.79,0.27-1.2c0.06-0.77-0.23-1.53-0.8-2.07c-0.63-0.42-1.38-0.63-2.13-0.6h-4.4v5.13L8.39,213.07z"/>
          <path d="M21.24,213c0.66,0.58,1.52,0.87,2.4,0.8c0.68,0.01,1.35-0.18,1.93-0.53c0.45-0.29,0.79-0.71,1-1.2h3.26
            c-0.39,1.39-1.24,2.61-2.4,3.46c-1.16,0.72-2.5,1.09-3.86,1.07c-0.98,0-1.95-0.18-2.86-0.53c-1.63-0.67-2.89-2-3.46-3.66
            c-0.33-0.92-0.49-1.89-0.47-2.86c-0.02-0.95,0.14-1.9,0.47-2.8c0.32-0.84,0.8-1.6,1.4-2.26c0.61-0.62,1.33-1.12,2.13-1.46
            c0.89-0.36,1.84-0.54,2.8-0.53c1.04-0.03,2.07,0.2,3,0.67c0.83,0.41,1.54,1.03,2.06,1.8c0.54,0.77,0.95,1.62,1.2,2.53
            c0.15,0.97,0.19,1.95,0.13,2.93h-9.66C20.3,211.35,20.63,212.28,21.24,213 M25.45,206.01c-0.57-0.51-1.31-0.77-2.06-0.73
            c-0.51-0.01-1.01,0.11-1.47,0.33c-0.35,0.19-0.67,0.44-0.93,0.73c-0.25,0.26-0.41,0.58-0.47,0.93c-0.07,0.28-0.12,0.57-0.13,0.87
            h5.99c-0.06-0.8-0.4-1.55-0.94-2.13L25.45,206.01z"/>
          <rect x="19.58" y="199.01" width="7.46" height="1.8"/>
        </g>
        </svg>
      )
    }
}
