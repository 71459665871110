import React, { Component } from 'react';
import Behance from './icons/behance';
import Pinterest from './icons/pinterest';
import Instagram from './icons/instagram';
import './sns.scss';

export default class SNS extends Component {
    render() {
      return (
        <div className="sns_list">
          <a href="https://www.pinterest.co.kr/A_ZERO_ONE/our-work" target="_blank">
            <Pinterest/>
          </a>
          <a href="https://www.instagram.com/A_ZERO_ONE_official/" target="_blank">
            <Instagram/>
          </a>
          <a href="https://www.behance.net/A-ZERO-ONE" target="_blank">
            <Behance/>
          </a>
        </div>
        
      )
    }
}
