import React, { Component } from 'react';

export default class Instagram extends Component {
    render() {
      return (
        <svg version="1.1" id="레이어_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30 30" xmlSpace="preserve">
        <g transform="translate(0 -1)">
          <g transform="translate(0 -192)">
            <path d="M15,195.7c4.01,0,4.48,0.02,6.06,0.09c0.95,0.01,1.89,0.19,2.78,0.52c1.31,0.5,2.35,1.54,2.85,2.85
              c0.33,0.89,0.51,1.83,0.52,2.79c0.07,1.58,0.09,2.06,0.09,6.06s-0.02,4.48-0.09,6.06c-0.01,0.95-0.19,1.89-0.52,2.78
              c-0.5,1.31-1.54,2.35-2.85,2.85c-0.89,0.33-1.83,0.51-2.78,0.52c-1.58,0.08-2.06,0.09-6.06,0.09s-4.48-0.02-6.06-0.09
              c-0.95-0.01-1.89-0.19-2.78-0.52c-1.31-0.5-2.35-1.54-2.85-2.85c-0.33-0.89-0.51-1.83-0.52-2.78C2.71,212.48,2.7,212,2.7,208
              s0.02-4.48,0.09-6.06c0.01-0.95,0.19-1.89,0.52-2.79c0.5-1.31,1.54-2.35,2.85-2.85c0.89-0.33,1.83-0.51,2.78-0.52
              C10.52,195.71,10.99,195.7,15,195.7 M15,193c-4.07,0-4.58,0.02-6.18,0.09c-1.24,0.03-2.48,0.26-3.64,0.7
              c-2.01,0.78-3.61,2.37-4.39,4.39c-0.44,1.17-0.67,2.4-0.7,3.64C0.01,203.42,0,203.93,0,208s0.02,4.58,0.09,6.18
              c0.03,1.24,0.26,2.48,0.7,3.64c0.78,2.01,2.37,3.61,4.39,4.39c1.17,0.44,2.4,0.67,3.64,0.7c1.6,0.08,2.11,0.09,6.18,0.09
              s4.58-0.02,6.18-0.09c1.24-0.03,2.48-0.26,3.64-0.7c2.01-0.78,3.61-2.37,4.39-4.39c0.44-1.17,0.67-2.4,0.7-3.64
              c0.07-1.6,0.09-2.11,0.09-6.18s-0.02-4.58-0.09-6.18c-0.03-1.24-0.26-2.48-0.7-3.64c-0.78-2.01-2.37-3.61-4.39-4.39
              c-1.17-0.44-2.4-0.67-3.64-0.7C19.58,193.01,19.07,193,15,193z"/>
            <path d="M15,200.3c-4.25,0-7.7,3.45-7.7,7.7s3.45,7.7,7.7,7.7s7.7-3.45,7.7-7.7v0C22.7,203.74,19.25,200.3,15,200.3z
              M15,213c-2.76,0-5-2.24-5-5s2.24-5,5-5s5,2.24,5,5l0,0C20,210.76,17.76,213,15,213z"/>
            <circle cx="23.01" cy="199.99" r="1.8"/>
          </g>
        </g>
        </svg>
        
      )
    }
}
