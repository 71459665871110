export const en = {
    contactUs: {
        address: [
            "2621, Nambusunhwan-ro,",
            "Gangnam-gu, Seoul,",
            "Republic of Korea"
        ],
        slogan: [
            "Don’t be shy.",
            "Tell me your project",
            "comfortably."
        ],
        form: {
            descript: ["Please select", "a project to proceed."],
            checkbox: {
                product: "Product Design",
                motion: "Digital Media Design",
                iden: "Identity Design",
                webDesign: "Web Design",
                package: "Package Design"
            },
            inputs: {
                name: "Name",
                phone: "Phone",
                email: "E-mail",
                descript: "Massage",
                budget: "Expected Estimation",
                necessary: "required entry"
            },
            submit: "Send",
            success: "Complete",
        }
    }
}