import React, { Component } from 'react';
import './sns.scss';

export default class MobileSNS extends Component {  
    render() {
      const { enableSubmit, send } = this.props;
      return (
        <div className="m_sns_list">
          <a href="https://www.behance.net/A-ZERO-ONE" target="_blank">
            <img src={require('../../assets/svg/behance.svg')} />
          </a>
          <a href="https://www.instagram.com/A_ZERO_ONE_official/" target="_blank">
            <img src={require('../../assets/svg/instagram.svg')} />
          </a>
          <a href="https://www.pinterest.co.kr/A_ZERO_ONE/our-work" target="_blank">
            <img src={require('../../assets/svg/pinterest.svg')} />
          </a>
          <button className={`${enableSubmit ? 'on' : ''}`}>
            <img  src={require(send 
              ? '../../assets/svg/message_send.svg'
              : '../../assets/svg/message_ready.svg'
              )
            } />
            {/* <img src={require('../../assets/svg/behance_over.svg')} /> */}
          </button>
        </div>
      )
    }
}
