import React, { Component } from 'react';
import './m_checkbox.scss';

export default class MobileContact extends Component {
    render() {
      return (
        <div className="m_checkbox">
          <input id={this.props.id} type="checkbox"/>
            <label htmlFor={this.props.id}>
              <svg>
                <polyline 
                  points="3,8 8,14 16,3"
                />
              </svg>
            </label>            
            <p>{this.props.text && this.props.text}</p>
        </div>
      )        
    }
}