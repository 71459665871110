import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Lang from '../../lang';
import Checkbox from '../../components/checkbox/m_checkbox';
import TextInput from '../../components/textInput/m_textInput';
import TextArea from '../../components/textInput/m_textArea';
import SNSbtn from '../../components/sns/m_sns';
import axios from 'axios';
import './m_contact.scss';

export default class MobileContact extends Component {
  state = {
    enableSubmit: false,
    send: false,
  }

  checkValue = (e) => {
    const name = this.refs.name.refs.text_input.value;
    const phone = this.refs.phone.refs.text_input.value;
    const email = this.refs.email.refs.text_input.value;
    const descript = this.refs.descript.refs.text_input.value;
    const budget = this.refs.budget.refs.text_input.value;
    this.setState({
      enableSubmit: (name !== '' && phone !== '' && email !== '' && descript !== '' && budget !== '')
    });
  }

  onSubmit = e => {
    e.preventDefault();
    const types = [];
    [].slice.call(e.target.children[1].children).forEach(e => {
      if (e.children[0].checked) {
        types.push(e.children[0].id);
      }
    });    
    const name = this.refs.name.refs.text_input.value;
    const phone = this.refs.phone.refs.text_input.value;
    const email = this.refs.email.refs.text_input.value;
    const descript = this.refs.descript.refs.text_input.value;
    const budget = this.refs.budget.refs.text_input.value;
    if (name === '') return this.refs.name.errorFocus();
    if (phone === '') return this.refs.phone.errorFocus();
    if (email === '') return this.refs.email.errorFocus();
    if (descript === '') return this.refs.descript.errorFocus();
    if (budget === '') return this.refs.budget.errorFocus();    
    axios.post('http://45.120.69.139/email_send', {
      key: 'A01_mail_good!!',
      from: email,
      text: `
        A01으로 요청된 문의
        --------------
        선택한 항목 ${types}

        이름 또는 기업명 : ${name}
        전화번호 : ${phone}
        내용 : ${descript}
        이메일: ${email}
        예산 : ${budget} 원
      `
    })
    .then((response) => {
      if (response.status === 200) this.setState({ send: true });
    })
    .catch(function (error) {
      console.log(error);
    });    
  }
  render() {
    const text = Lang[this.props.lang].contactUs;
    return [
      <div key="logo" className="m_logo"><img src={require('../../assets/svg/logo.svg')} /></div>,
      <div
        key="left"
        className="m_contact_left"
      >
        <h1>
          <img src={require('../../assets/svg/logo_text.svg')} />
        </h1>
        <ol className="m_main_nav">
          {/* <li><a href="/" target="_blank">About us</a></li> */}          
          <li className="active"><Link to="/contact_us">Contact us</Link></li>
          <li><Link to="/our_work">Our work</Link></li>
        </ol>
        <p>+82 70 8836 1248</p>
        <p>Hello@a-zero-one.com</p>
        <p>{text.address.map((t, idx) => <span key={`address${idx}`}>{t}</span>)}</p>
        <ul>
          <li className={this.props.lang === 'KR' ? 'on' : ''} onClick={() => this.props.setlang('KR')}>KR</li>
          <li className={this.props.lang === 'EN' ? 'on' : ''} onClick={() => this.props.setlang('EN')}>EN</li>
        </ul>
      </div>,
      <div
        key="right"
        className="m_contact_right"
      >
        <div className="top">
          <h2>
            {text.slogan.map((t, idx) => <span key={`slogan${idx}`}>{t}</span>)}
          </h2>
          <form onSubmit={this.onSubmit} onChange={this.checkValue}>
            <p className="contact_text">
              {text.form.descript.map((t, idx) => <span key={`descript${idx}`}>{t}</span>)}
            </p>
            <div className="contact_checkbox">
              <Checkbox text={text.form.checkbox.product} id="product"/>
              <Checkbox text={text.form.checkbox.motion} id="motion"/>          
              <Checkbox text={text.form.checkbox.iden} id="iden"/>
              <Checkbox text={text.form.checkbox.webDesign} id="web"/>
              <Checkbox text={text.form.checkbox.package} id="package"/>      
            </div>
            <div className="contact_textInput">
              <TextInput id="name" text={text.form.inputs.name} necessary ref="name" lang={this.props.lang} />
              <TextInput id="phone" text={text.form.inputs.phone} necessary type="tel" ref="phone" lang={this.props.lang}/>
              <TextInput id="email" text={text.form.inputs.email} necessary ref="email" lang={this.props.lang}/>
              <TextArea id="descript" text={text.form.inputs.descript} necessary ref="descript" lang={this.props.lang}/>
              <TextInput id="budget" text={text.form.inputs.budget} necessary type="tel" ref="budget" lang={this.props.lang}/>
            </div>
            <SNSbtn enableSubmit={this.state.enableSubmit} send={this.state.send} />
          </form>
        </div>          
        <div className="bot">        
          <p>Company Registration Number : 215-26-33030</p>
          <p>Copyright &copy; 2020 A_ZERO_ONE All Rights Reserved.</p>
        </div>          
      </div>
    ]
  }
}
