import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './ourWork.scss';
export default class OurWork extends Component {
    state = {
        select: -1,
    }

    set = select =>  setTimeout(() => this.setState({ select }), 300);

    render() {
        const select = this.state.select;
        const { isTablet, isMobile } = this.props;
      return [
        <div key="our_work" className="our_work" style={{ overflow: isTablet || isMobile ? 'auto' : 'hidden' }}>
            <div className="item" onMouseOver={() => this.set(1)} onMouseOut={() => this.set(-1)} >
                <div className="circle">
                    <img src={require("../../assets/img/parentory.jpg")} />
                </div>
                <div className="info">
                    <dl>
                        <dt>- CLIENT</dt>
                        <dd>Parentory</dd>
                    </dl>
                    <dl>
                        <dt>- PROJECT</dt>
                        <dd>Premium Infant Care Home Appliances</dd>
                    </dl>
                </div>
                <div className="filter" onClick={() =>window.location.href = "https://a-zero-one.myportfolio.com/parentory"} style={{ backgroundColor: '#ff006a' }} />
                <div className="line"/>
                <div className="num" onClick={() => {
                    if (select === 1) window.location.href = "https://a-zero-one.myportfolio.com/parentory"
                }}>
                    <span>1</span>
                    <span>2</span>
                </div>
                <div className="num2">12</div>
                <div className="line2"/>
            </div>
            <div className="item" onMouseOver={() => this.set(2)} onMouseOut={() => this.set(-1)}>
                <div className="circle">
                    <img src={require("../../assets/img/Imaniinfant.jpg")} />
                </div>
                <div className="info">
                    <dl>
                        <dt>- CLIENT</dt>
                        <dd>Imani</dd>
                    </dl>
                    <dl>
                        <dt>- PROJECT</dt>
                        <dd>Infant Care Brand</dd>
                    </dl>
                </div>
                <div className="filter" onClick={() =>window.location.href = "https://a-zero-one.myportfolio.com/imani"} style={{ backgroundColor: '#000cff' }} />
                <div className="line"/>
                <div className="num" onClick={() => {
                    if (select === 2) {
                        console.log(select);
                       window.location.href = "https://a-zero-one.myportfolio.com/imani";
                    }
                }}>
                    <span>1</span>
                    <span>1</span>
                </div>
                <div className="num2">11</div>
                <div className="line2"/>
            </div>            
            <div className="item" onMouseOver={() => this.set(3)} onMouseOut={() => this.set(-1)}>
                <div className="circle">
                    <img src={require("../../assets/img/spectrabasicbreastpump.jpg")} />
                </div>
                <div className="info">
                    <dl>
                        <dt>- CLIENT</dt>
                        <dd>Spectra</dd>
                    </dl>
                    <dl>
                        <dt>- PROJECT</dt>
                        <dd>Basic Breast Pump</dd>
                    </dl>
                </div>
                <div className="filter" onClick={() =>window.location.href = "https://a-zero-one.myportfolio.com/spectra-breast-pump"} style={{ backgroundColor: '#ffa300' }} />
                <div className="line"/>
                <div className="num" onClick={() => {
                    if (select === 3) {
                        console.log(select);
                       window.location.href = "https://a-zero-one.myportfolio.com/spectra-breast-pump";
                    }
                }}>
                    <span>1</span>
                    <span>0</span>
                </div>
                <div className="num2">10</div>
                <div className="line2"/>
            </div>
            <div className="item" onMouseOver={() => this.set(4)} onMouseOut={() => this.set(-1)}>
                <div className="circle">
                    <img src={require("../../assets/img/Nupay_BrandDesign.jpg")} />
                </div>
                <div className="info">
                    <dl>
                        <dt>- CLIENT</dt>
                        <dd>Nupay</dd>
                    </dl>
                    <dl>
                        <dt>- PROJECT</dt>
                        <dd>New Payment Platform</dd>
                    </dl>
                </div>
                <div className="filter" onClick={() =>window.location.href = "https://a-zero-one.myportfolio.com/nupay"} style={{ backgroundColor: '#00ffd4' }}/>
                <div className="line"/>
                <div className="num" onClick={() => {
                    if (select === 4)window.location.href = "https://a-zero-one.myportfolio.com/nupay"
                }}>
                    <span>0</span>
                    <span>9</span>
                </div>
                <div className="num2">09</div>
                <div className="line2"/>
            </div>
            <div className="item" onMouseOver={() => this.set(5)} onMouseOut={() => this.set(-1)}>
                <div className="circle">
                    <img src={require("../../assets/img/IOchecker.jpg")} />
                </div>
                <div className="info">
                    <dl>
                        <dt>- CLIENT</dt>
                        <dd>I/O</dd>
                    </dl>
                    <dl>
                        <dt>- PROJECT</dt>
                        <dd>Checker</dd>
                    </dl>
                </div>
                <div className="filter" onClick={() =>window.location.href = "https://a-zero-one.myportfolio.com/io-checker"} style={{ backgroundColor: '#2eff00' }}/>
                <div className="line"/>
                <div className="num" onClick={() => {
                    if (select === 5)window.location.href = "https://a-zero-one.myportfolio.com/io-checker"
                }}>
                    <span>0</span>
                    <span>8</span>
                </div>
                <div className="num2">08</div>
                <div className="line2"/>
            </div>
            <div className="item" onMouseOver={() => this.set(6)} onMouseOut={() => this.set(-1)} >
                <div className="circle">
                    <img src={require("../../assets/img/spectrasterilizer.jpg")} />
                </div>
                <div className="info">
                    <dl>
                        <dt>- CLIENT</dt>
                        <dd>Spectra</dd>
                    </dl>
                    <dl>
                        <dt>- PROJECT</dt>
                        <dd>Baby Bottle Sterilizer</dd>
                    </dl>
                </div>
                <div className="filter" onClick={() =>window.location.href = "https://a-zero-one.myportfolio.com/spectra-sterilizer"} style={{ backgroundColor: '#ffa300' }} />
                <div className="line"/>
                <div className="num" onClick={() => {
                    if (select === 6) window.location.href = "https://a-zero-one.myportfolio.com/spectra-sterilizer"
                }}>
                    <span>0</span>
                    <span>7</span>
                </div>
                <div className="num2">07</div>
                <div className="line2"/>
            </div> 
            <div className="item" onMouseOver={() => this.set(7)} onMouseOut={() => this.set(-1)}>
                <div className="circle">
                    <img src={require("../../assets/img/unixelectronic.jpg")} />
                </div>
                <div className="info">
                    <dl>
                        <dt>- CLIENT</dt>
                        <dd>Unixelectronic</dd>
                    </dl>
                    <dl>
                        <dt>- PROJECT</dt>
                        <dd>Smart Hairdryer</dd>
                    </dl>
                </div>
                <div className="filter" onClick={() =>window.location.href = "https://a-zero-one.myportfolio.com/unix-smarthairdryer"} style={{ backgroundColor: '#9400ff' }}/>
                <div className="line"/>
                <div className="num" onClick={() => {
                    if (select === 7)window.location.href = "https://a-zero-one.myportfolio.com/unix-smarthairdryer"
                }}>
                    <span>0</span>
                    <span>6</span>
                </div>
                <div className="num2">06</div>
                <div className="line2"/>
            </div>
            <div className="item" onMouseOver={() => this.set(8)} onMouseOut={() => this.set(-1)}>
                <div className="circle">
                    <img src={require("../../assets/img/IOlinker.jpg")} />
                </div>
                <div className="info">
                    <dl>
                        <dt>- CLIENT</dt>
                        <dd>I/O</dd>
                    </dl>
                    <dl>
                        <dt>- PROJECT</dt>
                        <dd>Linker</dd>
                    </dl>
                </div>
                <div className="filter" onClick={() =>window.location.href = "https://a-zero-one.myportfolio.com/io-linker"} style={{ backgroundColor: '#001da0' }}/>
                <div className="line"/>
                <div className="num" onClick={() => {
                    if (select === 8)window.location.href = "https://a-zero-one.myportfolio.com/io-linker"
                }}>
                    <span>0</span>
                    <span>5</span>
                </div>
                <div className="num2">05</div>
                <div className="line2"/>
            </div>
            <div className="item" onMouseOver={() => this.set(9)} onMouseOut={() => this.set(-1)}>
                <div className="circle">
                    <img src={require("../../assets/img/maruiBaby.jpg")} />
                </div>
                <div className="info">
                    <dl>
                        <dt>- CLIENT</dt>
                        <dd>Marui</dd>
                    </dl>
                    <dl>
                        <dt>- PROJECT</dt>
                        <dd>Baby Bottle Sterilizer</dd>
                    </dl>
                </div>
                <div className="filter" onClick={() =>window.location.href = "https://a-zero-one.myportfolio.com/marui"} style={{ backgroundColor: '#c14700' }}/>
                <div className="line"/>
                <div className="num" onClick={() => {
                    if (select === 9)window.location.href = "https://a-zero-one.myportfolio.com/marui"
                }}>
                    <span>0</span>
                    <span>4</span>
                </div>
                <div className="num2">04</div>
                <div className="line2"/>
            </div>
            <div className="item" onMouseOver={() => this.set(10)} onMouseOut={() => this.set(-1)}>
                <div className="circle">
                    <img src={require("../../assets/img/CowayAir.jpg")} />
                </div>
                <div className="info">
                    <dl>
                        <dt>- CLIENT</dt>
                        <dd>Coway</dd>
                    </dl>
                    <dl>
                        <dt>- PROJECT</dt>
                        <dd>Air Purifier Identity</dd>
                    </dl>
                </div>
                <div className="filter" onClick={() =>window.location.href = "https://a-zero-one.myportfolio.com/coway-airpurifier"} style={{ backgroundColor: '#00cece' }}/>
                <div className="line"/>
                <div className="num" onClick={() => {
                    if (select === 10)window.location.href = "https://a-zero-one.myportfolio.com/coway-airpurifier"
                }}>
                    <span>0</span>
                    <span>3</span>
                </div>
                <div className="num2">03</div>
                <div className="line2"/>
            </div>
            <div className="item" onMouseOver={() => this.set(11)} onMouseOut={() => this.set(-1)}>
                <div className="circle">
                    <img src={require("../../assets/img/Posco_HomeAutomationSystem.jpg")} />
                </div>
                <div className="info">
                    <dl>
                        <dt>- CLIENT</dt>
                        <dd>Posco</dd>
                    </dl>
                    <dl>
                        <dt>- PROJECT</dt>
                        <dd>Home Automation System</dd>
                    </dl>
                </div>
                <div className="filter" onClick={() =>window.location.href = "https://a-zero-one.myportfolio.com/posco-home-automation-system"} style={{ backgroundColor: '#c4007f' }}/>
                <div className="line"/>
                <div className="num" onClick={() => {
                    if (select === 11)window.location.href = "https://a-zero-one.myportfolio.com/posco-home-automation-system"
                }}>
                    <span>0</span>
                    <span>2</span>
                </div>
                <div className="num2">02</div>
                <div className="line2"/>
            </div>
            <div className="item" onMouseOver={() => this.set(12)} onMouseOut={() => this.set(-1)}>
                <div className="circle">
                    <img src={require("../../assets/img/vasco.jpg")} />
                </div>
                <div className="info">
                    <dl>
                        <dt>- CLIENT</dt>
                        <dd>Vasco</dd>
                    </dl>
                    <dl>
                        <dt>- PROJECT</dt>
                        <dd>Security Token</dd>
                    </dl>
                </div>
                <div className="filter" onClick={() =>window.location.href = "https://a-zero-one.myportfolio.com/vasco-meto"} style={{ backgroundColor: '#000cff' }}/>
                <div className="line"/>
                <div className="num" onClick={() => {
                    if (select === 12)window.location.href = "https://a-zero-one.myportfolio.com/vasco-meto"
                }}>
                    <span>0</span>
                    <span>1</span>
                </div>
                <div className="num2">01</div>
                <div className="line2"/>
            </div>
        </div>,
        <div key="logo" className={isMobile ? "m_logo" : "logo"}><img src={require('../../assets/svg/logo.svg')} /></div>,
        <ol key="nav" className={isMobile ? "m_main_nav" : "main_nav our_work_nav"}>        
          <li><Link to="/contact_us">Contact us</Link></li>           
          <li className="active"><Link to="/our_work">Our work</Link></li>
          {/* <li><a href="/" target="_blank">About us</a></li> */}
        </ol>
      ]
    }
}
