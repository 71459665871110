export const kr = {
    contactUs: {
        address: [
            "서울시 강남구 남부순환로 2621"
        ],
        slogan: [
            "망설이지 마세요.", 
            "당신이 원하는 프로젝트를",
            "우리에게 알려주세요."
        ],
        form: {
            descript: ["아래 프로젝트 항목 중 ", "선택해주세요."],
            checkbox: {
                product: "제품 디자인",
                motion: "영상 / 모션 디자인",
                iden: "아이덴티티 디자인",
                webDesign: "웹 디자인",
                package: "패키지 디자인"
            },
            inputs: {
                name: "이름 또는 기업명",
                phone: "전화번호",
                email: "이메일",
                descript: "내용",
                budget: "예산",
                necessary: "필수입력"
            },
            submit: "보내기",
            success: "성공",
        }
    }
}