import React, { Component } from 'react';
import Lang from '../../lang';
import './textInput.scss';

export default class TextInput extends Component {
    static defaultProps = {
        type: 'text',
    };
    state = {
        value: '',
        error: false,
    }
    errorFocus = () => {
        this.refs.text_input.focus();
        this.setState({ error: true });
    }
    render() {
    const { necessary, id, text, type, lang } = this.props;
    const { value, error } = this.state;
      return (
          <div className={`text_input ${error ? 'error' : ''}`}>
            <input 
                onChange={e => {
                    if (type === 'tel') {
                        if (!/^[0-9-]+$/.test(e.target.value) && e.target.value !== '') return;
                    }
                    this.setState({ value: e.target.value });
                }}
                onBlur={e => this.setState({ error: false })}
                value={value}
                id={id} ref="text_input"
                type={type}
                maxLength={id === 'budget' ? 15 : 524288}
            />
            {value !== '' ? null : <label 
                onClick={() => this.refs.text_input.focus()}
            >
                {necessary ? '*' : ''}{text} {necessary ? <span>({Lang[lang].contactUs.form.inputs.necessary})</span> : null}
            </label>}
            {
                ('budget' === id && value.length) ? <span style={{ left: value.length * 15 }} >{lang === 'KR' ? '원' : 'dollar'}</span> : null
            }
          </div>        
      )
    }
}
