import React, { Component } from 'react';
import Lang from '../../lang';
import './m_textInput.scss';

export default class MobileTextArea extends Component {
    state = {
        value: '',
        error: false,
    }
    errorFocus = () => {
        this.refs.text_input.focus();
        this.setState({ error: true });
    }
    render() {        
    const { necessary, id, text, lang } = this.props;
    const { value, error } = this.state;
      return (
        <div className={`m_text_input ${error ? 'error' : ''}`}>
            <textarea 
                onChange={e => this.setState({ value: e.target.value })}
                onBlur={e => this.setState({ error: false })}
                id={id} ref="text_input"
            />
            {value !== '' ? null : <label 
                onClick={() => this.refs.text_input.focus()}
            >
                {necessary ? '*' : ''}{text} {necessary ? <span>({Lang[lang].contactUs.form.inputs.necessary})</span> : null}
            </label>}
          </div>        
      )
    }
}
