import React, { Component } from 'react';

export default class Pinterest extends Component {
    render() {
      return (
        <svg version="1.1" id="레이어_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30 30" xmlSpace="preserve">
        <path d="M15.01,0C6.72,0,0,6.71,0,15c0,6.17,3.79,11.72,9.54,13.97c-0.2-1.43-0.18-2.88,0.05-4.3
          c0.27-1.17,1.76-7.46,1.76-7.46c-0.31-0.7-0.46-1.46-0.45-2.23c0-2.09,1.21-3.64,2.72-3.64c1.04-0.02,1.9,0.81,1.91,1.86
          c0,0.09,0,0.17-0.01,0.26c0,1.29-0.82,3.21-1.24,4.99c-0.3,1.17,0.41,2.35,1.58,2.65c0.21,0.05,0.43,0.08,0.65,0.06
          c2.67,0,4.72-2.81,4.72-6.87c0.1-3.27-2.46-6-5.73-6.11c-0.18-0.01-0.36,0-0.54,0.01c-3.58-0.16-6.62,2.62-6.77,6.2
          c0,0.1-0.01,0.2-0.01,0.3c0,1.23,0.39,2.43,1.12,3.42c0.11,0.12,0.14,0.28,0.1,0.43c-0.11,0.48-0.37,1.49-0.42,1.7
          c-0.06,0.27-0.22,0.33-0.5,0.2c-1.87-0.87-3.05-3.61-3.05-5.81c0-4.73,3.44-9.08,9.92-9.08c5.2,0,9.25,3.71,9.25,8.66
          c0,5.17-3.26,9.33-7.79,9.33c-1.37,0.05-2.66-0.6-3.44-1.72c0,0-0.75,2.86-0.93,3.57c-0.45,1.39-1.08,2.71-1.87,3.93
          c7.92,2.44,16.32-2,18.77-9.91c2.44-7.92-2-16.31-9.92-18.75C17.98,0.22,16.5,0,15.01,0"/>
        </svg>
        
      )
    }
}
